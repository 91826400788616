if ($("#contacts-block-container").length) {
    loadContactLandingBlock();
}

function loadContactLandingBlock() {
    var blockId = $('#contacts-block-container').attr('data-block-id');
    var currentCulture = $('#contacts-block-container').attr('data-current-culture');
    if (blockId) {
        $.get("/api/contacts/getitems/?id=" + blockId + "&currentLanguage=" + currentCulture, function (data, status) {
            $('#contacts-block-container').html(data);
        });
    };
}